import * as React from "react";
import { Link } from "gatsby";


import Darkmode from '../components/Header/Darkmode'
import Logo from '../components/Header/Logo'



import "./404.scss"

// markup
const NotFoundPage = () => {
  return (
    // <Layout>
    //   <Head title="404-Page not found"></Head>
    //   This is console asdfasdfasd;
    // </Layout>
    <div className="section__404">
      <div className="section__404__nav">
        <Logo />
        <Darkmode />
      </div>
      <p>
        Page not found 😞
      </p>
      <div className="btn--div">
        <Link className='btn-primary btn-v2' to="/">
          Go to home
          <i className="bi bi-arrow-right-short"></i>
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;

